<template>
  <default-layout :title="`Project: ${projectName}`">
    <v-container fluid tag="section">
      <unit-category-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const UnitCategoryForm = () =>
  import('@/components/project-management/unit-category/form/unit-category-form');

export default {
  name: 'unit-type-edit',
  components: { DefaultLayout, UnitCategoryForm },
  async created() {
    await this.$store.dispatch('unitCategory/form/restoreInitialState');
    this.$store.commit('unitCategory/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('unitCategory/form/SET_ID', this.$route.params.id);
    this.$store.dispatch('unitCategory/form/fetchBaseData');
  },
  computed: {
    projectName() {
      return this.$store.state.unitCategory.form.projectName;
    },
  },
};
</script>

<style scoped></style>
